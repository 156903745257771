<template>
    <v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition">
        <v-card tile v-if="client">
            <v-toolbar color="primary" dark height="70px">
                <v-row align="center">
                    <v-col cols="2">
                        <v-img :src="imgIcon" contain max-width="40"/>
                    </v-col>
                    <v-col cols="8" class="text-truncate text-center">
                        {{ client.name }}
                    </v-col>
                    <v-col cols="2" align="right">
                        <v-btn icon @click="hide">
                            <v-icon v-text="'mdi-close'"/>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-toolbar>
            <v-card-text>
                <v-row class="mt-3">
                    <v-col cols="12" md="10" offset-md="1" lg="8" offset-lg="2">
                        <v-row class="info-container">
                            <v-col cols="2" md="1" class="bottom-border primary" align="center">
                                <v-icon color="#FFFFFF" v-text="'$email'"/>
                            </v-col>
                            <v-col cols="10" md="11" class="bottom-border">
                                {{ client.email }}
                            </v-col>
                            <v-col cols="2" md="1" class="bottom-border ternary" align="center">
                                <v-icon color="#999999" v-text="'$phone'"/>
                            </v-col>
                            <v-col cols="10" md="11" class="bottom-border">
                                {{ client.phone }}
                            </v-col>
                            <v-col cols="2" md="1" class="primary" align="center">
                                <v-icon color="#FFFFFF" v-text="'mdi-map-marker'"/>
                            </v-col>
                            <v-col cols="10" md="11">
                                {{ client.region }} - {{ client.city }}
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row v-if="canUploadExams">
                    <v-col cols="12" class="text-center">
                        <v-btn color="secondary" class="normal-btn" small depressed @click="goToExams">
                            Subir examenes
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import {STATUS} from "@/utils/Constants";

export default {
    name: "ClientDetailsDialog",
    data() {
        return {
            dialog: false,
            client: null,
        }
    },
    computed: {
        ...mapState(['dialogActive']),
        canUploadExams(vm = this) {
            return vm.accountType === 'doctor' || vm.accountType === 'assistant'
        },
    },
    methods: {
        ...mapMutations(['setDialogActive']),
        show() {
            const vm = this
            vm.dialog = true
            vm.setDialogActive(true)
        },
        hide() {
            const vm = this
            vm.dialog = false
            vm.setDialogActive(false)
            vm.client = null
        },
        setClient(client) {
            const vm = this
            console.log('client',client)
            vm.client = client
        },
        goToExams() {
            const vm = this
            vm.goTo({name: `${vm.accountType}.client.exams`, params: {id: vm.client.id}})
        }
    },
    watch: {
        dialogActive(val) {
            if (!val) this.hide()
        }
    }
}
</script>

<style scoped>

.info-container {
    overflow: hidden;
    border-radius: 12px;
    border: 1px solid #cccccc;
    margin: 0;
}

.bottom-border {
    border-bottom: 1px solid #cccccc;
}


</style>