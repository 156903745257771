<template>
    <v-list>
        <v-list-item v-for="(client,i) in clients" :key="i" link>
            <v-list-item-content @click="onClick(client)">
                <v-list-item-title class="client-name" v-text="client.name"/>
            </v-list-item-content>
            <v-list-item-action v-if="showButton">
                <v-btn small color="secondary" class="normal-btn" depressed @click="showClientData(client)">
                    Ver datos
                </v-btn>
            </v-list-item-action>
        </v-list-item>
        <client-details-dialog v-if="showButton" ref="details"/>
    </v-list>
</template>

<script>
import ClientDetailsDialog from "@/components/client/ClientDetailsDialog";
export default {
    name: "Clients",
    components: {ClientDetailsDialog},
    props: {
        clients: {
            type: Array,
            default: () => [],
        },
        withEmit: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: 'questionnaires.client'
        },
        showButton: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        route(vm = this) {
            return `${vm.accountType}.${vm.type}`
        }
    },
    methods: {
        onClick(client) {
            const vm = this
            if (vm.withEmit) return vm.$emit('click', client)
            vm.goTo({name: vm.route, params: {id: client.id}})
        },
        showClientData(client) {
            const vm = this
            vm.$refs.details.setClient(client)
            vm.$refs.details.show()
        }
    }
}
</script>

<style scoped>

.client-name {
    text-align: center;
    background-color: var(--v-ternary-base);
    padding: 8px;
    border-radius: 12px;
    color: #4d4d4d;
}

</style>