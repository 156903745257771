<template>
    <master-panel>
        <template v-slot:body>
            <v-row>
                <v-col cols="12" v-if="loading">
                    <loading-spinner line height="0"/>
                </v-col>
                <v-col cols="12" v-else>
                    <clients show-button :clients="clients"/>
                </v-col>
                <v-col cols="12" class="text-center" v-if="clients.length === 0">
                    <small>{{ loading ? 'Cargando...' : 'Sin resultados' }}</small>
                </v-col>
            </v-row>
        </template>
        <template v-slot:buttons>
            <v-btn color="primary" class="normal-btn" small depressed @click="goHome">
                Dashboard
            </v-btn>
        </template>
    </master-panel>
</template>

<script>
import Clients from "@/components/lists/Clients";
import MasterPanel from "@/components/layouts/MasterPanel";
import LoadingSpinner from "@/components/common/LoadingSpinner";
import httpService from "@/services/httpService";

export default {
    name: "Questionnaires",
    components: {LoadingSpinner, MasterPanel, Clients},
    data() {
        return {
            loading: false,
            clients: []
        }
    },
    computed: {
        doctorId(vm = this) {
            return vm.currentUser?.doctor_id || null
        }
    },
    async mounted() {
        const vm = this
        await vm.loadQuestionnaires()
    },
    methods: {
        async loadQuestionnaires() {
            const vm = this
            vm.loading = true
            let response = await httpService.get(`doctor/${vm.doctorId}/questionnaires`)
            // console.log('loadQuestionnaires', response)
            vm.clients = response.data.clients
            vm.loading = false
        }
    },
}
</script>

<style scoped>

</style>